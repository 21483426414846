.crtt {
  animation-duration: 3s;
  animation-name: glissement;
  animation-iteration-count: infinite;
  text-align: center;
  color: red;
  font-style: italic;
}
@keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}
.crt2 {
  animation-duration: 3s;
  animation-name: glissement;
  animation-iteration-count: infinite;
  text-align: center;
  color: lime;
  font-style: italic;
}
@keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}

.crtt3 {
  animation-duration: 3s;
  animation-name: glissement;
  animation-iteration-count: infinite;
  text-align: center;
  color: rgb(148, 228, 0);
  font-style: italic;
}
@keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}
