.addFamily {
  background: transparent !important;
  align-items: inherit !important;
  text-align: initial !important;
}
button {
  font-weight: bold !important;
}
Button:hover {
  background: white !important;
  color: red !important;
  font-weight: bold !important;
}
