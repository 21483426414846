#contact {
  padding: 60px 0;
  text-align: center;
}

#contact h3 {
  margin: 0 auto 20px auto;
  width: 400px;
  border-bottom: 2px solid #02b8dd;
  padding: 20px;
  color: black;
}

#contact p {
  margin-bottom: 40px;
}

label {
  font-weight: bold;
  font-size: 20px;
  margin-right: 10px;
  color: #777;
}
input[type="text"] {
  padding: 10px;
  font-size: 20px;
  margin-right: 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
}

/* form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  height: 100%;
  flex-direction: column !important;
  text-align: center;
} */

.button-3 {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 11px;
  background-color: red;

  border-radius: 5px;
}

.button-3:hover {
  background: black;
  color: red;
}
