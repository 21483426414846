#sidebar {
  /* background: linear-gradient(45deg, black, transparent); */
  background-color: #f3f4f6;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 100vh;
  width: 25vw;
  position: fixed;
  top: 2;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
  /* color: #E85B6B; */
}
.link {
  color: black !important;
  text-decoration: none;
}

.sidebar__img {
  display: flex;
  align-items: center;
}

.sidebar__title > div > img {
  width: 75px;
  object-fit: contain;
}

.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline;
  color: black;
  font-weight: bold;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: green;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.active_menu_link {
  background: darkgray;
  color: #3ea175;
}

.active_menu_link a {
  color: white !important;
}

.sidebar__link > a {
  text-decoration: none;
  color: black !important;

  font-weight: 700;
}
.sidebar__link a:hover {
  color: red !important;
}

.sidebar__logout {
  margin-top: 20px;
  padding: 10px;
  color: #e65061;
}

.sidebar__logout > a {
  text-decoration: none;
  color: red !important;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.sidebar__logout > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar-responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
  }

  .sidebar__title > i {
    display: inline;
  }
}
