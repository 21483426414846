.fact {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid gainsboro;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  color: black;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.total {
  border: 1px solid gainsboro;
  float: right;
  padding: 4px 30px;
  margin-bottom: 10px;
  font-weight: bold;
}
