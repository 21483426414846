@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://font.googleapis.com/css2?family=Lato:wgh@300;400;700&display=swap);
/* * {
  margin: 0;
  padding: 0;
} */
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
} */

a {
  text-decoration: none;
  color: grey;
}

a:checked {
  text-decoration: none;
  color: grey;
}
.secMainConainer {
  width: 74vw;
  margin-left: 25vw;
}
.secMainConainer-cont {
  padding: 10px 30px;
}
.containerDashMain {
  display: flex;
}
.MainSec {
  display: flex;
}
.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}
/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 15px solid rgba(33, 150, 243, 1);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

main {
  background: #f3f4f6;
  grid-area: main;
  overflow-y: auto;
}
.crtt {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: glissement;
          animation-name: glissement;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  text-align: center;
  color: red;
  font-style: italic;
}
@-webkit-keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}
@keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}

.main__container {
  padding: 20px 35px;
  margin: 0 !important;
}

.main__title {
  display: flex;
  align-items: center;
}

.main__title > img {
  max-height: 100px;
  object-fit: contain;
  margin-right: 20px;
}

.main__greeting > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.main__greeting > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.main__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  margin: 20px 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70px;
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
}

.card_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_inner > span {
  font-size: 25px;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 50px;
}

.charts__left {
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
}

.charts__left__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__left__title > div > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts__left__title > div > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts__left__title > i {
  color: #ffffff;
  font-size: 20px;
  background: #ffc100;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}

.charts__right {
  padding: 25px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
}

.charts__right__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__right__title > div > h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts__right__title > div > p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts__right__title > i {
  color: #ffffff;
  font-size: 20px;
  background: red;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}

.charts__right__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 30px;
}

.card1 {
  background: #d1ecf1;
  color: #35a4ba;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card2 {
  background: #d2f9ee;
  color: #38e1b0;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card3 {
  background: #d6d8d9;
  color: #3a3e41;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card4 {
  background: #fddcdf;
  color: #f65a6f;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

@media only screen and (max-width: 855px) {
  .main__cards {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}
/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 15px solid rgba(33, 150, 243, 1);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/* .me-auto {
  margin-left: 23%;
}
.nav-link {
  font-size: 15px;
  color: white !important;
} */

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.wrapper h1 {
  font-size: 45px;
  color: #444;
  /* color: white; */
}

ul {
  list-style: none;
}

h2 {
  color: #444;
  font-size: 55px;
}

h4 {
  font-size: 24px;
  color: #444;
}

h3 {
  font-size: 30px;
  color: white;
}

.wrapper p {
  line-height: 20px;
  color: #777;
}

a {
  text-decoration: none;
  color: #444;
}

.clear {
  clear: both;
}

.wrapper {
  width: 940px;
  margin: 0 auto;
  padding: 0 10px;
}

header {
  height: 90px;
  background-color: #ffffff;
}

header h1 {
  float: left;
  padding-top: 18px;
}

header nav {
  float: right;
  margin-top: 32px;
}

header nav ul li {
  display: inline-block;
}

header nav ul li a {
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 20px;
  text-decoration: none;
}

.navbar {
  background: #ffffff;
  /* background: linear-gradient(45deg, black, transparent) !important; */

  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid lightgray;
}

a {
  text-decoration: none !important;
}

.nav_icon {
  display: none;
}

.nav_icon > i {
  font-size: 26px;
  color: #a5aaad;
}

.navbar__left > a {
  margin-right: 30px;
  text-decoration: none;
  color: #a5aaad;
  font-size: 15px;
  font-weight: 700;
}

.navbar__left .active_link {
  color: #265acc;
  border-bottom: 3px solid #265acc;
  padding-bottom: 12px;
}

.navbar__right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__right > a {
  margin-left: 20px;
  text-decoration: none;
}

.navbar__right > a > i {
  color: #a5aaad;
  font-size: 16px;
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
  padding: 7px;
}

@media only screen and (max-width: 978px) {
  .nav_icon {
    display: inline;
  }
}

@media only screen and (max-width: 480px) {
  .navbar__left {
    display: none;
  }
}

#sidebar {
  /* background: linear-gradient(45deg, black, transparent); */
  background-color: #f3f4f6;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  transition: all 0.5s;
  height: 100vh;
  width: 25vw;
  position: fixed;
  top: 2;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
  /* color: #E85B6B; */
}
.link {
  color: black !important;
  text-decoration: none;
}

.sidebar__img {
  display: flex;
  align-items: center;
}

.sidebar__title > div > img {
  width: 75px;
  object-fit: contain;
}

.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline;
  color: black;
  font-weight: bold;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: green;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.active_menu_link {
  background: darkgray;
  color: #3ea175;
}

.active_menu_link a {
  color: white !important;
}

.sidebar__link > a {
  text-decoration: none;
  color: black !important;

  font-weight: 700;
}
.sidebar__link a:hover {
  color: red !important;
}

.sidebar__logout {
  margin-top: 20px;
  padding: 10px;
  color: #e65061;
}

.sidebar__logout > a {
  text-decoration: none;
  color: red !important;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.sidebar__logout > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar-responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
  }

  .sidebar__title > i {
    display: inline;
  }
}

.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}
/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 15px solid rgba(33, 150, 243, 1);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.familiesList h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  color: rgb(102, 101, 101);
  /* font-size: 2.5rem; */
  padding: 24px 0;
}

table {
  width: 100%;
  table-layout: fixed;
}
.tbl-header {
  /* background-color: rgba(255, 255, 255, 0.3); */
  /* background-color: grey; */
  /* background-color: maroon; */
  background-color: rgb(36, 132, 156);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* background-color: black; */
}
.tbl-content {
  height: 93px;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.tbl-content::nth-of-type(2) {
  background: green;
}
th {
  padding: 20px 15px;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
}
td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 400;
  font-size: 12px;
  color: rgb(49, 49, 49);
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

/* demo styles */
body {
  /* background: -webkit-linear-gradient(left, #25c481, #25b7c4); */
  /* background: linear-gradient(to right, #25c513, #25b7c4); */

  /* background: linear-gradient(to right, #25c481, #25b7c4); */
  font-family: "Roboto", sans-serif;
}

/* follow me template */
.made-with-love {
  margin-top: 40px;
  padding: 10px;
  clear: left;
  text-align: center;
  font-size: 10px;
  font-family: arial;
  color: #fff;
}
.made-with-love i {
  font-style: normal;
  color: #f50057;
  font-size: 14px;
  position: relative;
  top: 2px;
}
.made-with-love a {
  color: #fff;
  text-decoration: none;
}
.made-with-love a:hover {
  text-decoration: underline;
}

/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}


.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}
/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 15px solid rgba(33, 150, 243, 1);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.addFamily {
  background: transparent !important;
  align-items: inherit !important;
  text-align: left !important;
  text-align: initial !important;
}
button {
  font-weight: bold !important;
}
Button:hover {
  background: white !important;
  color: red !important;
  font-weight: bold !important;
}

#contact {
  padding: 60px 0;
  text-align: center;
}

#contact h3 {
  margin: 0 auto 20px auto;
  width: 400px;
  border-bottom: 2px solid #02b8dd;
  padding: 20px;
  color: black;
}

#contact p {
  margin-bottom: 40px;
}

label {
  font-weight: bold;
  font-size: 20px;
  margin-right: 10px;
  color: #777;
}
input[type="text"] {
  padding: 10px;
  font-size: 20px;
  margin-right: 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
}

/* form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  height: 100%;
  flex-direction: column !important;
  text-align: center;
} */

.button-3 {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 11px;
  background-color: red;

  border-radius: 5px;
}

.button-3:hover {
  background: black;
  color: red;
}

/* body {
    background-color: #eee
} */

.one {
  width: 270px;
  background-color: #000;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pic1 {
  position: relative;
  right: 22px;
  top: 23px;
}

.pic2 {
  position: relative;
  left: 25px;
  top: 17px;
}

.pic3 {
  position: relative;
  right: 13px;
  bottom: 18px;
}

.pic4 {
  position: relative;
  left: 14px;
  bottom: 21px;
}

.project {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #fd4040;
}

.quote {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.two {
  width: 270px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.star {
  font-size: 20px !important;
  color: #b1b4b7;
}

.dot {
  font-size: 20px !important;
  color: #b1b4b7;
}

.round {
  border-radius: 50%;
  background-color: #eee;
  width: 33px;
  height: 33px;
  margin-bottom: -10px;
  align-items: center;
  justify-content: center;
}

.name {
  font-size: 22px;
  color: #464e56;
  font-weight: 600;
  text-align: left;
}

.quote2 {
  font-size: 12px;
  font-weight: 500;
  color: #868e94;
  text-align: left;
}

.img1 {
  position: relative;
  left: 20px;
  z-index: 28;
  border: 0.5px solid #6ebde4;
  border-radius: 50%;
  background-color: #bcd8e6;
}

.img2 {
  position: relative;
  left: 10px;
  z-index: 29;
  border: 0.5px solid #6ebde4;
  border-radius: 50%;
  background-color: #bcd8e6;
}

.img3 {
  z-index: 30;
  border: 0.5px solid #6ebde4;
  border-radius: 50%;
  background-color: #bcd8e6;
}

.task {
  color: #727475;
}

.date {
  color: #727475;
}

.imgfix {
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  top: 2px;
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-around;
  /* height: 100vh; */
  /* align-items: center; */
  /* background-color: #f6f6f6; */
}

.main-container {
  padding: 30px;
}

/* HEADING */

.heading {
  text-align: center;
}

.heading__title {
  font-weight: 600;
}

.heading__credits {
  margin: 10px 0px;
  color: #888888;
  font-size: 25px;
  transition: all 0.5s;
}

.heading__link {
  text-decoration: none;
}

.heading__credits .heading__link {
  color: inherit;
}

/* CARDS */

/* .cardss {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cardd {
  margin: 20px;
  padding: 20px;
  width: 100%;
  min-height: 200px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
} */

.cardd:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.card__link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
  padding: 15px;
}

.card__title {
  /* grid-row: 3/4; */
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */

.card-1 {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.card-2 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.card-3 {
  background: radial-gradient(#76b2fe, #b69efe);
}

/* .card-4 {
  background: radial-gradient(#60efbc, #58d5c9);
} */
.card-6 {
  background: linear-gradient(498deg, #e90001, transparent);
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .cards {
    justify-content: center;
  }
}

.main-container {
  padding: 30px;
}

/* HEADING */

.heading {
  text-align: center;
}

.heading__title {
  font-weight: 600;
}

.heading__credits {
  margin: 10px 0px;
  color: #888888;
  font-size: 25px;
  transition: all 0.5s;
}

.heading__link {
  text-decoration: none;
}

.heading__credits .heading__link {
  color: inherit;
}

/* CARDS */

/* .cardss {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cardd {
  margin: 20px;
  padding: 20px;
  width: 100%;
  min-height: 200px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
} */

.cardd:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.card__link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
  padding: 15px;
}

.card__title {
  /* grid-row: 3/4; */
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */

.card-1 {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.card-2 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.card-3 {
  background: radial-gradient(#76b2fe, #b69efe);
}

/* .card-4 {
  background: radial-gradient(#60efbc, #58d5c9);
} */
.card-7 {
  background: goldenrod;
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .cards {
    justify-content: center;
  }
}

/* USER LIST TABLE */
.user-list tbody td > img {
  position: relative;
  max-width: 50px;
  float: left;
  margin-right: 15px;
}
/* .userCont {
  position: relative;
  width: 7;
} */
.user-list tbody td .user-link {
  display: block;
  font-size: -0.25em;
  padding-top: 3px;
  margin-left: 60px;
  text-decoration: none;
}
.user-list tbody td .user-subhead {
  font-size: 0.875em;
  font-style: italic;
  color: red;
  font-size: 12px;
}

/* TABLES */
.table {
  border-collapse: separate;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #eee;
}
.table thead > tr > th {
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 0;
}
.table tbody > tr > td {
  font-size: 0.875em;
  background: #f5f5f5;
  border-top: 10px solid #fff;
  vertical-align: middle;
  padding: 12px 8px;
  color: black;
}
.table tbody > tr > td:first-child,
.table thead > tr > th:first-child {
  padding-left: 20px;
}
.table thead > tr > th span {
  border-bottom: 2px solid #c2c2c2;
  display: inline-block;
  padding: 0 5px;
  padding-bottom: 5px;
  font-weight: normal;
  color: black;
}
.table thead > tr > th > a span {
  color: #344644;
}
.table thead > tr > th > a span:after {
  content: "\f0dc";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  margin-left: 5px;
  font-size: 0.75em;
}
.table thead > tr > th > a.asc span:after {
  content: "\f0dd";
}
.table thead > tr > th > a.desc span:after {
  content: "\f0de";
}
.table thead > tr > th > a:hover span {
  text-decoration: none;
  color: #2bb6a3;
  border-color: #2bb6a3;
}
.table.table-hover tbody > tr > td {
  transition: background-color 0.15s ease-in-out 0s;
}
.table tbody tr td .call-type {
  display: block;
  font-size: 0.75em;
  text-align: center;
}
.table tbody tr td .first-line {
  line-height: 1.5;
  font-weight: 400;
  font-size: 1.125em;
}
.table tbody tr td .first-line span {
  font-size: 0.875em;
  color: #969696;
  font-weight: 300;
}
.table tbody tr td .second-line {
  font-size: 0.875em;
  line-height: 1.2;
}
.table a.table-link {
  margin: 0 5px;
  font-size: 1.125em;
}
.table a.table-link:hover {
  text-decoration: none;
  color: #2aa493;
}
.table a.table-link.danger {
  color: #fe635f;
}
.table a.table-link.danger:hover {
  color: #dd504c;
}

.table-products tbody > tr > td {
  background: none;
  border: none;
  border-bottom: 1px solid #ebebeb;
  transition: background-color 0.15s ease-in-out 0s;
  position: relative;
}
.table-products tbody > tr:hover > td {
  text-decoration: none;
  background-color: #f6f6f6;
}
.table-products .name {
  display: block;
  font-weight: 600;
  padding-bottom: 7px;
}
.table-products .price {
  display: block;
  text-decoration: none;
  width: 50%;
  float: left;
  font-size: 0.875em;
}
.table-products .price > i {
  color: #8dc859;
}
.table-products .warranty {
  display: block;
  text-decoration: none;
  width: 50%;
  float: left;
  font-size: 0.875em;
}
.table-products .warranty > i {
  color: #f1c40f;
}
.table tbody > tr.table-line-fb > td {
  background-color: #9daccb;
  color: #262525;
}
.table tbody > tr.table-line-twitter > td {
  background-color: #9fccff;
  color: #262525;
}
.table tbody > tr.table-line-plus > td {
  background-color: #eea59c;
  color: #262525;
}
.table-stats .status-social-icon {
  font-size: 1.9em;
  vertical-align: bottom;
}
.table-stats .table-line-fb .status-social-icon {
  color: #556484;
}
.table-stats .table-line-twitter .status-social-icon {
  color: #5885b8;
}
.table-stats .table-line-plus .status-social-icon {
  color: #a75d54;
}

/* * {
  box-sizing: border-box;
  width: 100%;
} */

h1 {
  font-weight: bold;
  margin: 0;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
  font-weight: bold;
  margin-top: 15px !important;
}

span {
  font-size: 12px;
  padding: 15px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  margin: 4px !important;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 600px;
  margin-top: 30px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
}

.log-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
}

.overlay {
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-image: url(/static/media/GRP.f7a30a4e.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  position: relative;
  left: -70%;
  height: 100%;
  width: 200%;
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
}

.overlay-right {
  right: 142px;
}

.social-container {
  margin: 50px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

body {
  /* background: #f7f7ff; */
  background-color: transparent !important;
  margin-top: 20px;
}
.card {
  position: relative;
  /* height: 928px !important; */
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
}
.me-2 {
  margin-right: 0.5rem !important;
}

element.style {
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.px-4 {
  padding-right: 1.5 rem !important;
  padding-left: 1.5 rem !important;
}
.btn-primary {
  color: #fff !important;
  background-color: red !important;
  /* border-color: #0d6efd; */
}
.btn-outline-primary {
  color: white !important;
  border-color: transparent !important;
  background-color: red !important;
}

.fact {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  color: black;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.fact {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid gainsboro;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  color: black;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.total {
  border: 1px solid gainsboro;
  float: right;
  padding: 4px 30px;
  margin-bottom: 10px;
  font-weight: bold;
}

.crt2 {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: glissement;
          animation-name: glissement;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  text-align: center;
  color: lime;
  font-style: italic;
}
@-webkit-keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}
@keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}

.main-container {
  padding: 30px;
}

/* HEADING */

.heading {
  text-align: center;
}

.heading__title {
  font-weight: 600;
}

.heading__credits {
  margin: 10px 0px;
  color: #888888;
  font-size: 25px;
  transition: all 0.5s;
}

.heading__link {
  text-decoration: none;
}

.heading__credits .heading__link {
  color: inherit;
}

/* CARDS */

.cardss {
  /* display: block;
  flex-wrap: wrap; */
  /* justify-content: space-around; */
  /* width: 100%; */
  /* justify-content: space-between; */
}

.cardd {
  margin: 20px;
  padding: 20px;
  width: 100%;
  min-height: 200px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}

.cardd:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.card__link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
}

.card__title {
  /* grid-row: 3/4; */
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */

.card-1 {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.card-2 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.card-3 {
  background: radial-gradient(#76b2fe, #b69efe);
}

/* .card-4 {
  background: radial-gradient(#60efbc, #58d5c9);
} */
.card-4 {
  /* background: #58d650; */
  background: dimgrey;
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .cards {
    justify-content: center;
  }
}

.main-container {
  padding: 30px;
}

/* HEADING */

.heading {
  text-align: center;
}

.heading__title {
  font-weight: 600;
}

.heading__credits {
  margin: 10px 0px;
  color: #888888;
  font-size: 25px;
  transition: all 0.5s;
}

.heading__link {
  text-decoration: none;
}

.heading__credits .heading__link {
  color: inherit;
}

/* CARDS */

/* .cardss {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cardd {
  margin: 20px;
  padding: 20px;
  width: 100%;
  min-height: 200px;
  display: grid;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
} */

.cardd:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.card__link,
.card__exit,
.card__icon {
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
}

.card__link::after {
  position: absolute;
  top: 25px;
  left: 0;
  content: "";
  width: 0%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.5s;
}

.card__link:hover::after {
  width: 100%;
}

.card__exit {
  grid-row: 1/2;
  justify-self: end;
}

.card__icon {
  grid-row: 2/3;
  font-size: 30px;
}

.card__title {
  /* grid-row: 3/4; */
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  color: #ffffff;
}

.card__apply {
  grid-row: 4/5;
  align-self: center;
}

/* CARD BACKGROUNDS */

.card-1 {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.card-2 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.card-3 {
  background: radial-gradient(#76b2fe, #b69efe);
}

/* .card-4 {
  background: radial-gradient(#60efbc, #58d5c9);
} */
.card-5 {
  background: black;
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .cards {
    justify-content: center;
  }
}

.crtt {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: glissement;
          animation-name: glissement;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  text-align: center;
  color: red;
  font-style: italic;
}
@-webkit-keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}
@keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}
.crt2 {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: glissement;
          animation-name: glissement;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  text-align: center;
  color: lime;
  font-style: italic;
}
@keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}

.crtt3 {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: glissement;
          animation-name: glissement;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  text-align: center;
  color: rgb(148, 228, 0);
  font-style: italic;
}
@keyframes glissement {
  from {
    margin-left: 30%;
    margin-right: 30%;
    width: 70%;
  }
  to {
    margin-left: 0%;
    margin-right: 0%;
    width: 70%;
  }
}

/* styling the scroll bar */

::webkit-scrollbar {
  width: 5px;
  height: 6px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: #3ea175 !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}
/* general styling */
* {
  padding: 0;
  margin: 0;
}
body {
  box-sizing: border-box;
  font-family: sans-serif;
}
.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}
.font-bold {
  font-weight: 700;
}
.text-title {
  color: #2e4a66;
}
.text-lightblue {
  color: #469cac;
}
.text-red {
  color: red;
}
.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}
.containerDash {
  display: grid;
  height: 100vh;
  /* grid-template-columns: 0.8fr 1fr 1fr 1fr; */
  grid-auto-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
}
@media only screen and (max-width: 978px) {
  .containerDash {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }
} ;

