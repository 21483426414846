/* .me-auto {
  margin-left: 23%;
}
.nav-link {
  font-size: 15px;
  color: white !important;
} */

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.wrapper h1 {
  font-size: 45px;
  color: #444;
  /* color: white; */
}

ul {
  list-style: none;
}

h2 {
  color: #444;
  font-size: 55px;
}

h4 {
  font-size: 24px;
  color: #444;
}

h3 {
  font-size: 30px;
  color: white;
}

.wrapper p {
  line-height: 20px;
  color: #777;
}

a {
  text-decoration: none;
  color: #444;
}

.clear {
  clear: both;
}

.wrapper {
  width: 940px;
  margin: 0 auto;
  padding: 0 10px;
}

header {
  height: 90px;
  background-color: #ffffff;
}

header h1 {
  float: left;
  padding-top: 18px;
}

header nav {
  float: right;
  margin-top: 32px;
}

header nav ul li {
  display: inline-block;
}

header nav ul li a {
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 20px;
  text-decoration: none;
}
