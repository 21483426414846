body {
  /* background: #f7f7ff; */
  background-color: transparent !important;
  margin-top: 20px;
}
.card {
  position: relative;
  /* height: 928px !important; */
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
}
.me-2 {
  margin-right: 0.5rem !important;
}

element.style {
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.px-4 {
  padding-right: 1.5 rem !important;
  padding-left: 1.5 rem !important;
}
.btn-primary {
  color: #fff !important;
  background-color: red !important;
  /* border-color: #0d6efd; */
}
.btn-outline-primary {
  color: white !important;
  border-color: transparent !important;
  background-color: red !important;
}
