@import url("https://font.googleapis.com/css2?family=Lato:wgh@300;400;700&display=swap");

/* styling the scroll bar */

::webkit-scrollbar {
  width: 5px;
  height: 6px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: #3ea175 !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}
/* general styling */
* {
  padding: 0;
  margin: 0;
}
body {
  box-sizing: border-box;
  font-family: sans-serif;
}
.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}
.font-bold {
  font-weight: 700;
}
.text-title {
  color: #2e4a66;
}
.text-lightblue {
  color: #469cac;
}
.text-red {
  color: red;
}
.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}
.containerDash {
  display: grid;
  height: 100vh;
  /* grid-template-columns: 0.8fr 1fr 1fr 1fr; */
  grid-auto-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";
}
@media only screen and (max-width: 978px) {
  .containerDash {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "nav"
      "main";
  }
} ;
